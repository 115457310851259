import { Injectable } from "@angular/core";

import { registerLocaleData } from "@angular/common";
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';

import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

import { Logger } from "../logger/";
import { Languages } from "src/app/shared/models/languages.model";

const log = new Logger("I18N");

@Injectable({
  providedIn: "root"
})
export class I18nService {
  languageKey = "language"; //for localStorage
  defaultLanguage: string = '';
  supportedLanguages: Array<string> = [];
  constructor(private translate: TranslateService) {}

  init(defaultLanguage: string, supportedLanguages: Array<Languages>) {
    this.defaultLanguage = defaultLanguage;
    let sLanguages: Array<string> = [];
    for (const supported of supportedLanguages) {
      sLanguages.push(supported.locale);
    }
    this.supportedLanguages = sLanguages;
    this.language = "";

    registerLocaleData(en, 'en');
    registerLocaleData(it, 'it');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguageKey(event.lang);
    });
  }

  set language(language: string) {
    log.debug("Setting new language: " + language);
    language = this.getCurrentLanguage(language);
    log.debug("Using: " + language);
    this.translate.use(language);
  }

  get language(): string {
    return this.translate.currentLang;
  }

  private getCurrentLanguage(language: string): string {
    language = language || this.getLanguageKey() || this.translate.getBrowserLang() as string;

    let isLanguageSupported = this.supportedLanguages.includes(language);

    return isLanguageSupported ? language : this.defaultLanguage;
  }

  private getLanguageKey(): string {
    log.debug("Getting saved language");
    return localStorage.getItem(this.languageKey) as string;
  }

  private setLanguageKey(current: string): void {
    log.debug("Storing language: " + current);
    // this.facade.locale(current);
    localStorage.setItem(this.languageKey, current);
  }

  public changeLoader(loader: string) {}
}
