import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  defaultLanguage: 'en',
  apiUrl: 'https://devapi.voverc.com',
  googlePlacesApiKey: 'AIzaSyButP_itSUxAj98vsXTDCNYKs9Ec58CzPE',
  supportedLanguages: [
    {
      locale: "it",
      name: "Italiano"
    },
    {
      locale: "en",
      name: "English"
    }
  ],
};
